.container {
  cursor: pointer;
  font-family: "Helvetica Neue";
}
@media screen and (min-width: 768px) {
  .container {
    padding: 24px 12px;
    border: 1.5px solid #e7e6e4;
    display: grid;
    grid-template-columns: 489fr 338fr 300fr;
    gap: 0px 10px;
    grid-auto-flow: row;
  }
  .container:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .container:last-of-type {
    border-bottom-width: 3px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    border-radius: 5px !important;
    border: 1.5px solid #e7e6e4;
  }
  .container:nth-of-type(odd) {
    background-color: #ffffff;
  }
  .container:nth-of-type(even) {
    background-color: #f7f7f7;
  }
}

.leftContainer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .previewImageCtn {
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    overflow: hidden;
  }
}

.previewImage {
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .previewImage {
    border-radius: 5px;
    margin-right: 12px;
    min-width: 132px;
    max-width: 132px;
  }
}
@media screen and (max-width: 768px) {
  .previewImage {
    width: 100%;
    height: 100%;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: #272522;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .createdForStaticText {
    font-size: 10px;
    font-weight: 500;
  }
}

.offeringName {
  font-size: 12px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .offeringName {
    color: rgba(0, 0, 0, 0.4);
  }
}
@media screen and (max-width: 768px) {
  .offeringName {
    color: rgba(0, 0, 0, 0.85);
  }
}

.offeringAvailabilityStatusCtn {
  display: flex;
  align-items: center;
  gap: 4px;
}
@media screen and (min-width: 768px) {
  .offeringAvailabilityStatusCtn {
    justify-content: center;
  }
}

.offeringAvailabilityStatus {
  border-radius: 10000px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  line-height: normal;
}

.offeringAvailabilityStatus_1 {
  background: #00ac72;
}

.offeringAvailabilityStatus_2 {
  background: #ffaa00;
}

.offeringAvailabilityStatus_3 {
  background: #aa2822;
}

.actionsCtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #49494c;
}

.immediateActionsCtn {
  display: flex;
  align-items: center;
  gap: 16px;
}

.actionBtn {
  cursor: pointer;
}

.menuPopoverContainer {
  box-shadow: rgba(0, 0, 0, 0.12) 2px 2px 4px !important;
}
@media screen and (max-width: 768px) {
  .menuPopoverContainer {
    border-radius: 12px 12px 0 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .menuPopoverContainer {
    border-radius: 12px !important;
    border: 1px solid #e7e6e4;
  }
}

.templateSelectingStageIcon.on circle {
  fill: #eeecf9 !important;
}
.templateSelectingStageIcon.on path {
  fill: #493ab1 !important;
}
.templateSelectingStageIcon.after circle {
  fill: #00b779 !important;
}
.templateSelectingStageIcon.after path {
  fill: white !important;
}

.detailsEditingStageIcon.before path {
  fill: #c2c2c2 !important;
}
.detailsEditingStageIcon.before circle {
  fill: transparent !important;
}
.detailsEditingStageIcon.on path {
  fill: #493ab1 !important;
}
.detailsEditingStageIcon.on circle {
  fill: #eeecf9 !important;
}

.stageText {
  font-size: 14px;
  font-weight: 400;
  color: rgba(73, 58, 177, 1);
}
