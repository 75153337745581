.featureTextContainer {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
}

.innerBox {
  width: 65%;
  text-align: center;
}

.heading {
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  color: #272522;
}

.description {
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  color: #4e4a44;
}

.upgradeBtn {
  width: fit-content;
  padding: 5px 8px;
  background: #493ab1;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #493ab1;
}

@media screen and (max-width: 780px) {
  .featureTextContainer {
    padding: 16px;
    bottom: 6px;
  }

  .innerBox {
    width: 100%;
  }
}
