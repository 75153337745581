.icon {
  width: 32px;
  height: 32px;
  color: #493ab1;
  margin-bottom: 16px;
}

.btnIcon {
  width: 20px !important;
  height: 20px !important;
  color: #493ab1;
  margin-right: 6px;
}
