.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
}

.erroricon {
  fill: #e94235 !important;
  font-size: 84px !important;
}

.heading {
  font-size: 2em;
  margin-bottom: 20px;
}

.message {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.accordion {
  border: 1.5px solid #e7e6e4;
  box-shadow: rgb(14 13 12 / 14%) 0px 3px 0px;
  border-radius: 11px;
  min-height: 73px;
  padding: 9px 0px 0px 3px;
}

.accordianSummaryRoot {
  min-height: 63px;
}

.accordianTitle {
  font-size: 18px;
}

.accordianDescription {
  white-space: pre-wrap;
}
