.featureContainer {
  top: 100px !important;
  left: 6px !important;
}

@media only screen and (max-width: 767px) {
  .featureContainer {
    height: calc(100vh - 240px);
  }
}
