.menuItem {
  font-size: 14px;
  padding: 12.5px 12px;
}
.menuItem:hover {
  background-color: #cfcfcf;
}
.menuItem:not(:last-of-type) {
  border-bottom: 1px solid #e7e6e4;
}

.goToTrafficAnalysisTopRightIcon {
  font-size: 1rem !important;
  transform: rotate(-45deg);
}

.menuPopoverItemMUIIcon {
  font-size: 20px !important;
}

.copyButtonIcon {
  transform: rotateZ(-45deg);
}
